import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Validation } from '../../interfaces/validation/validation';
import { ValidationProblem } from '../../interfaces/validation/validation-problem';
import { Order } from '../../interfaces/orders/order';
import { PathConversionService } from '../../services/path-conversion/path-conversion.service';
import { OrderManagerService } from '../../services/order-manager/order-manager.service';
import { ValidationService } from '../../services/validation/validation.service';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'lib-validation-problems',
  templateUrl: './validation-problems.component.html',
  styleUrls: ['./validation-problems.component.css'],
  animations: [
    trigger('slideDown', [
      state('hidden', style({
        margin: '0px',
        padding: '0px',
        height: '0px',
        opacity: 0
      })),
      state('visible', style({
        margin: '*',
        padding: '*',
        height: '*',
        opacity: 1
      })),
      transition('hidden <=> visible', [
        animate('0.5s cubic-bezier(0.5, 0.1, 0.1, 1)')
      ])
    ])
  ]
})
export class ValidationProblemsComponent implements OnInit, OnDestroy {
  @Input() saving$: Observable<boolean> | undefined;
  @Input() validation: Validation | null | undefined;
  @Input() useStaticValidation = false;
  @Input() collapsible = false;

  private order!: Order;
  protected showWarnings = false;
  protected showErrors = false;
  protected showNotices = false;

  protected collapseWarnings = true;
  protected collapseErrors = true;
  protected collapseNotices = true;

  private savingSubscription!: Subscription;

  constructor(
    private orderManagerService: OrderManagerService,
    private pathConverstionService: PathConversionService,
    private validationService: ValidationService) { }

  ngOnInit(): void {
    this.order = this.orderManagerService.currentOrder.value;

    if(this.saving$) {
      this.saving$
        .subscribe(value => {
          if (value === false) {
            this.showValidation();
            
            this.savingSubscription?.unsubscribe();
          }
        });
    } else {
      this.showValidation();
    }
  }

  ngOnDestroy(): void {
    this.savingSubscription?.unsubscribe();
  }

  getMessage(problem: ValidationProblem): string {
    const convertedPath = this.pathConverstionService.convertPath(this.order, problem.path);

    if (convertedPath) {
      return `${convertedPath}: ${problem.userFriendlyMessage}`;
    } else {
      return problem.userFriendlyMessage ?? '';
    }
  }

  hasWarnings() {
    return this.validation?.warnings?.filter(warn => !!warn.userFriendlyMessage).length ?? 0 > 0;
  }

  hasErrors() {
    return this.validation?.errors?.length ?? 0 > 0;
  }

  hasNotices() {
    return this.validation?.notices?.filter(notice => !!notice.userFriendlyMessage).length ?? 0 > 0;
  }

  showValidation() {
    if (!this.useStaticValidation) {
      this.validation = this.validationService.currentValidation$.value;
    }
    
    this.order = this.orderManagerService.currentOrder.value;

    if (this.hasWarnings()) {
      this.showWarnings = true;
    }

    if (this.hasErrors()) {
      this.showErrors = true;
    }

    if (this.hasNotices()) {
      this.showNotices = true;
    }
  }

  clearValidation() {
    this.validation = null;
    this.showWarnings = false;
    this.showErrors = false;
    this.showNotices = false;
  }

  shouldShowNotices() {
    if (this.collapsible) {
      return !this.collapseNotices;
    } else {
      return this.showNotices;
    }
  }

  getNotices(): ValidationProblem[] {
    return this.validation?.notices?.filter(w => !!w.userFriendlyMessage) ?? [];
  }

  shouldShowWarnings() {
    if (this.collapsible) {
      return !this.collapseWarnings;
    } else {
      return this.showWarnings;
    }
  }

  getWarnings(): ValidationProblem[] {
    return this.validation?.warnings?.filter(w => !!w.userFriendlyMessage) ?? [];
  }

  shouldShowErrors() {
    if (this.collapsible) {
      return !this.collapseErrors;
    } else {
      return this.showErrors;
    }
  }

  getErrors(): ValidationProblem[] {
    return this.validation?.errors?.filter(w => !!w.userFriendlyMessage) ?? [];
  }

}
