import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { REG_HUB_COMMON_CONFIG, RegHubCommonConfig } from '../../reg-hub-common-config';
import { Observable } from 'rxjs';
import { GeneralCollateralHistory } from '../../interfaces/general-collateral/general-collateral-history';
import { ValidatedOrder } from '../../interfaces/validated-order';
import { Order } from '../../interfaces/orders/order';

@Injectable({
  providedIn: 'root'
})
export class OrderGroupsService {
  constructor(
    private http: HttpClient,
    @Inject(REG_HUB_COMMON_CONFIG) private config: RegHubCommonConfig) { }

  public getGeneralCollateralHistory(orderGroupId: string): Observable<GeneralCollateralHistory[]> {
    return this.http.get<GeneralCollateralHistory[]>(`${this.config.rootUrl}/OrderGroups/${orderGroupId}/Orders/GeneralCollateralHistory`);
  }

  public getOrderGroupOrders(orderGroupId: string, pageSize: number = 10): Observable<Order[]> {
    const params = new HttpParams().append('pageSize', pageSize);
    return this.http.get<Order[]>(`${this.config.rootUrl}/OrderGroups/${orderGroupId}/Orders`, { params: params });
  }

  public getOrderGroupOrdersWithValidation(orderGroupId: string, pageSize: number = 10): Observable<ValidatedOrder[]> {
    const headers = new HttpHeaders().set('accept', 'application/json+validation');
    const params = new HttpParams().append('pageSize', pageSize);

    return this.http.get<ValidatedOrder[]>(`${this.config.rootUrl}/OrderGroups/${orderGroupId}/Orders`, { headers: headers, params: params });
  }
}
