import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: 'fullJurisdictionName'
})
export class FullJurisdictionNamePipe implements PipeTransform {
    transform(jurisdiction: string, country: string) {
        if (country === 'CA') {
            switch (jurisdiction) {
                case 'BC': return 'British Columbia';
                case 'AB': return 'Alberta';
                case 'SK': return 'Saskatchewan';
                case 'MB': return 'Manitoba';
                case 'ON': return 'Ontario';
                case 'QC': return 'Quebec';
                case 'YT': return 'Yukon';
                case 'NT': return 'Northwest Territories';
                case 'NU': return 'Nunavut';
                case 'NL': return 'Newfoundland and Labrador';
                case 'NB': return 'New Brunswick';
                case 'NS': return 'Nova Scotia';
                case 'PE': return 'Prince Edward Island';
                case 'CW': return 'Country-Wide';
                default: return jurisdiction;
            }
        } else {
            return jurisdiction;
        }
    }
}