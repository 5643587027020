<table mat-table matSort [dataSource]="orders" multiTemplateDataRows *ngIf="orders.value.length > 0">
    <ng-container matColumnDef="orderTypeID">
        <th mat-header-cell *matHeaderCellDef mat-sort-header id="orderTypeID"> Type </th>
        <td mat-cell *matCellDef="let order"> {{order.orderType.name}} </td>
    </ng-container>

    <ng-container matColumnDef="orderStatusTypeID">
        <th mat-header-cell *matHeaderCellDef mat-sort-header id="orderStatusTypeID"> Status
        </th>
        <td mat-cell *matCellDef="let order"> {{order.orderStatusTypeID}} </td>
    </ng-container>

    <ng-container matColumnDef="country">
        <th mat-header-cell *matHeaderCellDef mat-sort-header id="country"> Country </th>
        <td mat-cell *matCellDef="let order"> {{order.country}} </td>
    </ng-container>

    <ng-container matColumnDef="jurisdiction">
        <th mat-header-cell *matHeaderCellDef mat-sort-header id="jurisdiction"> Jurisdiction
        </th>
        <td mat-cell *matCellDef="let order">{{ order.displayJurisdiction }}
        <td>
    </ng-container>

    <ng-container matColumnDef="documents">
        <th mat-header-cell *matHeaderCellDef mat-sort-header id="documents"> Documents
        </th>
        <td mat-cell *matCellDef="let order">
            <lib-download-search-documents [order]="order" *ngIf="order.searchResult"></lib-download-search-documents>
        <td>
    </ng-container>

    <ng-container matColumnDef="view">
        <th mat-header-cell *matHeaderCellDef aria-label="row actions">&nbsp;</th>
        <td mat-cell *matCellDef="let order">
            <button mat-icon-button color="accent" (click)="viewOrder(order, false)" aria-label="View Order">
                <mat-icon>article</mat-icon>
            </button>
            <button mat-icon-button color="accent" (click)="viewOrder(order, true)" aria-label="View Order">
                <mat-icon>open_in_new</mat-icon>
            </button>
        </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let order; columns: displayedColumns;" class="expandable-table-row">
    </tr>

</table>