import { Component, OnDestroy, OnInit } from '@angular/core';
import { Order } from '../../interfaces/orders/order';
import { Subject } from 'rxjs';
import { OrderManagerService } from '../../services/order-manager/order-manager.service';
import { eBusinessSearchCriteriaType, eBusinessSearchCriteriaTypeString } from '../../enums';
import { Province } from '../../interfaces/province';
import { SearchJurisdiction } from 'projects/reg-hub-common/src/public-api';

@Component({
  selector: 'lib-order-business-search-criteria',
  templateUrl: './order-business-search-criteria.component.html',
  styleUrls: ['./order-business-search-criteria.component.css']
})
export class OrderBusinessSearchCriteriaComponent implements OnInit, OnDestroy {
  order!: Order

  provinces: Province[] = [
    { value: 'AB', viewValue: 'AB' },
    { value: 'BC', viewValue: 'BC' },
    { value: 'MB', viewValue: 'MB' },
    { value: 'NB', viewValue: 'NB' },
    { value: 'NL', viewValue: 'NL' },
    { value: 'NS', viewValue: 'NS' },
    { value: 'ON', viewValue: 'ON' },
    { value: 'PE', viewValue: 'PE' },
    { value: 'QC', viewValue: 'QC' },
    { value: 'SK', viewValue: 'SK' },
    { value: 'NT', viewValue: 'NT' },
    { value: 'NU', viewValue: 'NU' },
    { value: 'YT', viewValue: 'YT' },
    { value: 'CW', viewValue: 'FED' }
  ]

  private unsubscribe$ = new Subject<void>();

  constructor(private orderManager: OrderManagerService) { }

  ngOnInit(): void {
      this.order = this.orderManager.currentOrder.value;
  }

  isNameSearch(): boolean {
    return this.order.businessSearchCriteria!.businessSearchCriteriaTypeID == eBusinessSearchCriteriaType.Name ||
      this.order.businessSearchCriteria!.businessSearchCriteriaTypeID.toString() == eBusinessSearchCriteriaTypeString.Name;
  }

  isNumberSearch() {
    return this.order.businessSearchCriteria!.businessSearchCriteriaTypeID == eBusinessSearchCriteriaType.Number ||
      this.order.businessSearchCriteria!.businessSearchCriteriaTypeID.toString() == eBusinessSearchCriteriaTypeString.Number;
  }

  getSearchType(): string {
    return this.isNameSearch() ? eBusinessSearchCriteriaTypeString.Name : eBusinessSearchCriteriaTypeString.Number;
  }

  getJurisdictionLabel(jurisdiction: string): string {
    const province = this.provinces.find(p => p.value === jurisdiction);
    return province ? province.viewValue : 'Unknown Jurisdiction';
  }

  getJurisdictionLabels(jurisdictions: SearchJurisdiction[] | undefined): string[] {
    if(jurisdictions === undefined) {
      jurisdictions = [];
    }

    return jurisdictions.map(j => this.getJurisdictionLabel(j.jurisdiction));
  }

  ngOnDestroy(): void {
      // Signal all subscriptions to complete
      this.unsubscribe$.next();
      this.unsubscribe$.complete();
  }
}
