<button color="primary" [disabled]="disableSelectAll()" class="padding-bottom" mat-stroked-button
    (click)="selectAllToggles()">Select All</button>
<button [disabled]="disableDeselectAll()" mat-stroked-button (click)="deselectAllToggles()"
    class="margin-left-5">Deselect All</button>

<div class="jurisdiction-container padding-top">
    <div *ngFor="let jurisdiction of selectionJurisdictions" class="jurisdiction-item">
        <mat-slide-toggle [checked]="isChecked(jurisdiction.jurisdictionCode)"
            (change)="onCheckedChange($event, jurisdiction)">
            &nbsp;&nbsp;&nbsp;{{ getSlideToggleLabel(jurisdiction.jurisdictionFullName) }}
        </mat-slide-toggle>
    </div>
</div>