import { NgModule, ModuleWithProviders } from '@angular/core';

import { CommonModule, DatePipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// Angular Material Modules)
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatListModule } from '@angular/material/list';
import { MatIconModule } from '@angular/material/icon';
import { MatTableModule } from '@angular/material/table';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatRadioModule } from '@angular/material/radio';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTreeModule } from '@angular/material/tree';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

// Components
import { SearchBarComponent } from './search-bar/search-bar.component';
import { PlaceholderComponent } from './placeholder/placeholder.component';
import { DisclosureIndicatorComponent } from './disclosure-indicator/disclosure-indicator.component';
import { ListIssueComponent } from './list-issue/list-issue.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { MessageComponent } from './message/message.component';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { JurisdictionSelectionComponent } from './jurisdiction-selection/jurisdiction-selection.component';
import { FilterComponent } from './filter/filter.component';
import { HttpClientModule } from '@angular/common/http';
import { AddressFormComponent } from './address-form/address-form.component';
import { HandleDebtorComponent } from './handle-debtor/handle-debtor.component';
import { HandleSecuredPartyComponent } from './handle-secured-party/handle-secured-party.component';
import { DebtorListComponent } from './debtor-list/debtor-list.component';
import { SecuredPartyListComponent } from './secured-party-list/secured-party-list.component';
import { HandleAssetComponent } from './handle-asset/handle-asset.component';
import { AssetListComponent } from './asset-list/asset-list.component';
import { OrderRequestingUserDetailsComponent } from './order-requesting-user-details/order-requesting-user-details.component';
import { OrderDetailsComponent } from './order-details/order-details.component';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { ExternalOrderDetailsComponent } from './external-order-details/external-order-details.component';
import { OrderSearchCriteriaComponent } from './order-search-criteria/order-search-criteria.component';
import { GeneralCollateralComponent } from './general-collateral/general-collateral.component';
import { LienAdditionalInformationComponent } from './lien-additional-information/lien-additional-information.component';
import { OrderHistoryComponent } from './order-history/order-history.component';
import { SigningLocationComponent } from './signing-location/signing-location.component';
import { LienDetailsComponent } from './lien-details/lien-details.component';
import { LienResultsComponent } from './lien-results/lien-results.component';
import { DocumentDownloadComponent } from './document-download/document-download.component';
import { DischargeDetailsComponent } from './discharge-details/discharge-details.component';
import { HandleExistingPartyComponent } from './handle-existing-party/handle-existing-party.component';
import { DealerListComponent } from './dealer-list/dealer-list.component';
import { HandleDealerComponent } from './handle-dealer/handle-dealer.component';
import { OrderChangesComponent } from './order-changes/order-changes.component';
import { REG_HUB_COMMON_CONFIG, RegHubCommonConfig } from '../reg-hub-common-config';
import { LocalizedDatePipe } from '../localized-date-pipe';
import { UTCToLocalDatePipe } from '../utc-to-local-date-pipe';
import { OrderPreviewHostComponent } from './order-preview-host/order-preview-host.component';
import { SearchOrderPreviewComponent } from './search-order-preview/search-order-preview.component';
import { RegistrationOrderPreviewComponent } from './registration-order-preview/registration-order-preview.component';
import { DefaultOrderPreviewComponent } from './default-order-preview/default-order-preview.component';
import { RenewalOrderPreviewComponent } from './renewal-order-preview/renewal-order-preview.component';
import { ErrorBubbleComponent } from './error-bubble/error-bubble.component';
import { OrderMessagesComponent } from './order-messages/order-messages.component';
import { MatMenuModule } from '@angular/material/menu';
import { PartiesListComponent } from './parties-list/parties-list.component';
import { PartiesFormComponent } from './parties-form/parties-form.component';
import { GeneralCollateralHistoryExpandableComponent } from './general-collateral-history-expandable/general-collateral-history-expandable.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { GeneralCollateralHistoryPopupComponent } from './general-collateral-history-popup/general-collateral-history-popup.component';
import { IdleCountdownDialogComponent } from './idle-countdown-dialog/idle-countdown-dialog.component';
import { ChangeTextComponent } from './change-text/change-text.component';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { REG_HUB_DATE_FORMATS, RegHubDateAdapter } from '../reghub-date-adapter';
import { ExistingRegistrationDetailsComponent } from './existing-registration-details/existing-registration-details.component';
import { AmendmentResultsComponent } from './amendment-results/amendment-results.component';
import { LienLifetimeComponent } from './lien-lifetime/lien-lifetime.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { RouterModule } from '@angular/router';
import { ValidationProblemsComponent } from './validation-problems/validation-problems.component';
import { OrderBusinessSearchCriteriaComponent } from './order-business-search-criteria/order-business-search-criteria.component';
import { MatChipsModule } from '@angular/material/chips';
import { BusinessSearchResultsComponent } from './business-search-results/business-search-results.component';
import { BusinessSearchResultsFilterComponent } from './business-search-results-filter/business-search-results-filter.component';
import { MatPaginatorModule } from '@angular/material/paginator';
import { BusinessSearchResultPreviewComponent } from './business-search-result-preview/business-search-result-preview.component';
import { OrderBusinessReportCriteriaComponent } from './order-business-report-criteria/order-business-report-criteria.component';
import { MessageListComponent } from './message-list/message-list.component';
import { MatSortModule } from '@angular/material/sort';
import { TruncatePipe } from '../truncate-pipe';
import { ReplaceCharacterDirective } from '../replace-character.directive';
import { MultiJurisdictionSelectComponent } from './multi-jurisdiction-select/multi-jurisdiction-select.component';
import { FullJurisdictionNamePipe } from '../jurisdiction-full-name-pipe';
import { MultiSearchOrdersComponent } from './multi-search-orders/multi-search-orders.component';
import { DownloadSearchDocumentsComponent } from './download-search-documents/download-search-documents.component';
import { FullCountryNamePipe } from '../country-full-name-pipe';

@NgModule({
  declarations: [
    ConfirmDialogComponent,
    DisclosureIndicatorComponent,
    ListIssueComponent,
    SearchBarComponent,
    PageNotFoundComponent,
    PlaceholderComponent,
    MessageComponent,
    JurisdictionSelectionComponent,
    FilterComponent,
    AddressFormComponent,
    HandleDebtorComponent,
    HandleSecuredPartyComponent,
    DebtorListComponent,
    SecuredPartyListComponent,
    HandleAssetComponent,
    AssetListComponent,
    OrderDetailsComponent,
    OrderRequestingUserDetailsComponent,
    ExternalOrderDetailsComponent,
    OrderSearchCriteriaComponent,
    OrderHistoryComponent,
    SigningLocationComponent,
    GeneralCollateralComponent,
    LienAdditionalInformationComponent,
    LienDetailsComponent,
    LienResultsComponent,
    DocumentDownloadComponent,
    DischargeDetailsComponent,
    HandleExistingPartyComponent,
    DealerListComponent,
    HandleDealerComponent,
    OrderChangesComponent,
    LocalizedDatePipe,
    UTCToLocalDatePipe,
    TruncatePipe,
    FullJurisdictionNamePipe,
    ReplaceCharacterDirective,
    OrderPreviewHostComponent,
    SearchOrderPreviewComponent,
    RegistrationOrderPreviewComponent,
    DefaultOrderPreviewComponent,
    RenewalOrderPreviewComponent,
    ErrorBubbleComponent,
    OrderMessagesComponent,
    PartiesListComponent,
    PartiesFormComponent,
    GeneralCollateralHistoryExpandableComponent,
    GeneralCollateralHistoryPopupComponent,
    IdleCountdownDialogComponent,
    ChangeTextComponent,
    ExistingRegistrationDetailsComponent,
    AmendmentResultsComponent,
    LienLifetimeComponent,
    ValidationProblemsComponent,
    OrderBusinessSearchCriteriaComponent,
    BusinessSearchResultsComponent,
    BusinessSearchResultsFilterComponent,
    BusinessSearchResultPreviewComponent,
    OrderBusinessReportCriteriaComponent,
    MultiJurisdictionSelectComponent,
    MessageListComponent,
    MultiSearchOrdersComponent,
    DownloadSearchDocumentsComponent,
    FullCountryNamePipe
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    RouterModule,

    // Angular Material
    MatCardModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
    MatCheckboxModule,
    MatSelectModule,
    MatListModule,
    MatDialogModule,
    MatDatepickerModule,
    MatTableModule,
    MatSortModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatMenuModule,
    NgxMatSelectSearchModule,
    MatTooltipModule,
    MatTreeModule,
    MatExpansionModule,
    MatProgressBarModule,
    MatChipsModule,
    MatPaginatorModule,
    MatSlideToggleModule
  ],
  exports: [
    //ConfirmDialogComponent,
    DisclosureIndicatorComponent,
    ListIssueComponent,
    MessageComponent,
    PlaceholderComponent,
    PageNotFoundComponent,
    SearchBarComponent,
    JurisdictionSelectionComponent,
    FilterComponent,
    DebtorListComponent,
    SecuredPartyListComponent,
    AddressFormComponent,
    HandleDebtorComponent,
    HandleSecuredPartyComponent,
    HandleAssetComponent,
    AssetListComponent,
    OrderDetailsComponent,
    OrderRequestingUserDetailsComponent,
    ExternalOrderDetailsComponent,
    OrderSearchCriteriaComponent,
    OrderHistoryComponent,
    SigningLocationComponent,
    GeneralCollateralComponent,
    LienAdditionalInformationComponent,
    LienDetailsComponent,
    LienResultsComponent,
    DocumentDownloadComponent,
    DischargeDetailsComponent,
    HandleExistingPartyComponent,
    DealerListComponent,
    HandleDealerComponent,
    OrderChangesComponent,
    LocalizedDatePipe,
    UTCToLocalDatePipe,
    TruncatePipe,
    FullJurisdictionNamePipe,
    ReplaceCharacterDirective,
    OrderPreviewHostComponent,
    SearchOrderPreviewComponent,
    RegistrationOrderPreviewComponent,
    DefaultOrderPreviewComponent,
    ErrorBubbleComponent,
    OrderMessagesComponent,
    PartiesListComponent,
    PartiesFormComponent,
    GeneralCollateralHistoryExpandableComponent,
    GeneralCollateralHistoryPopupComponent,
    IdleCountdownDialogComponent,
    ChangeTextComponent,
    ExistingRegistrationDetailsComponent,
    AmendmentResultsComponent,
    LienLifetimeComponent,
    ValidationProblemsComponent,
    OrderBusinessSearchCriteriaComponent,
    BusinessSearchResultsComponent,
    OrderBusinessReportCriteriaComponent,
    MultiJurisdictionSelectComponent,
    MessageListComponent,
    MultiSearchOrdersComponent,
    DownloadSearchDocumentsComponent,
    FullCountryNamePipe
  ]
})
export class UiCommonModule {
  static forRoot(config: RegHubCommonConfig): ModuleWithProviders<UiCommonModule> {
    return {
      ngModule: UiCommonModule,
      providers: [
        {
          provide: REG_HUB_COMMON_CONFIG,
          useValue: config
        },
        DatePipe,
        { provide: MAT_DATE_FORMATS, useValue: REG_HUB_DATE_FORMATS },
      ]
    };
  }
}
