import { Injectable } from '@angular/core';
import { Order } from '../../interfaces/orders/order';
import { eOrderType, ePartyType } from '../../enums';
import { BehaviorSubject } from 'rxjs';
import { ValidatedOrder } from '../../interfaces/validated-order';

@Injectable({
  providedIn: 'root'
})
export class OrderManagerService {
  private initialOrder: Order = {
    id: "",
    referenceNumber: "",
    country: "",
    jurisdiction: "",
    orderTypeID: eOrderType.notSet,
  } as Order;

  private initialParentOrder: Order = {
    id: "",
    referenceNumber: "",
    country: "",
    jurisdiction: "",
    orderTypeID: eOrderType.notSet,
  } as Order;

  // BehaviorSubject to hold the current state of the order
  currentOrder = new BehaviorSubject<Order>(this.initialOrder);
  parentOrder = new BehaviorSubject<Order>(this.initialParentOrder);

  constructor() { }

  // Method to update the order
  updateOrder(order: Order) {
    this.currentOrder.next(order);
  }

  updateLien(values: any) {
    const currentOrder = this.currentOrder.value;

    for (const key in values) {
      if (key in currentOrder.lien!) {
        (currentOrder.lien! as any)[key] = values[key];
      }
    }

    this.currentOrder.next(currentOrder);
  }

  // Method to update the order
  updateParentOrder(order: Order) {
    this.parentOrder.next(order);
  }

  clearOrder() {
    this.currentOrder.next(this.initialOrder);
  }

  clearParentOrder() {
    this.parentOrder.next(this.initialParentOrder);
  }

  getCurrentOrderValueForPut(): any {
    const order = this.currentOrder.value;

    if (order.orderTypeID === eOrderType.renewal ||
      order.orderTypeID === eOrderType.discharge) {
      return this.mapOrderToBaseDetails(order);
    }

    if (order.orderTypeID == eOrderType.basicDischarge ||
      order.orderTypeID == eOrderType.basicRenewal) {
      return this.mapBasicLien(order);
    }

    return order;
  }

  mapOrderToBaseDetails(order: Order) {
    return {
      id: order.id,
      referenceNumber: order.referenceNumber,
      orderTypeID: order.orderTypeID,
      jurisdiction: order.jurisdiction,
      country: order.country,
      term: order.lien?.term,
      isInfiniteTerm: order.lien?.isInfiniteTerm,
      expiryDate: order.lien?.expiryDate,
      discharge: order.discharge
    }
  }

  mapBasicLien(order: Order) {
    if (order.lien?.registrationNumber == null && order.referenceRegistrationInformation?.originalRegistrationNumber != null) {
      order.lien!.registrationNumber = order.referenceRegistrationInformation!.originalRegistrationNumber;
    }

    if (order.lien?.rin == null && order.referenceRegistrationInformation?.rin != null) {
      order.lien!.rin = order.referenceRegistrationInformation!.rin;
    }

    if (order.lien?.originalQCFormTypeID == null && order.referenceRegistrationInformation?.originalQCFormTypeID) {
      order.lien!.originalQCFormTypeID = order.referenceRegistrationInformation!.originalQCFormTypeID
    }

    if (order.lien?.originalSigningDate == null && order.referenceRegistrationInformation?.originalSigningDate) {
      order.lien!.originalSigningDate = order.referenceRegistrationInformation!.originalSigningDate
    }

    if (order.lien?.currentExpiryDate == null && order.referenceRegistrationInformation?.originalExpiryDate) {
      order.lien!.currentExpiryDate = order.referenceRegistrationInformation!.originalExpiryDate
    }

    if (order.lien?.originalOrderTypeID == null && order.referenceRegistrationInformation?.originalOrderTypeID) {
      order.lien!.originalOrderTypeID = order.referenceRegistrationInformation!.originalOrderTypeID
    }

    if (order.lien?.dischargeType == null && order.discharge?.dischargeType) {
      order.lien!.dischargeType = order.discharge.dischargeType;
    }

    if (order.debtor == null) {
      order.debtor = order.parties?.find(party => party.partyTypeID == ePartyType.businessDebtor || party.partyTypeID == ePartyType.individualDebtor);
    }

    if (order.securedParty == null) {
      order.securedParty = order.parties?.find(party => party.partyTypeID == ePartyType.businessSecuredParty || party.partyTypeID == ePartyType.individualSecuredParty);
    }

    if (order.registeringAgent == null) {
      order.registeringAgent = order.parties?.find(party => party.partyTypeID == ePartyType.businessRegisteringAgent || party.partyTypeID == ePartyType.individualRegisteringAgent);
    }

    return order;
  }
}
